import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Rachid from "../images/photo-rachid.jpg";
import Nicolas from "../images/photo-nicolas.jpg";

function VisitePage() {
  return (
    <Layout>
      <SEO title="Qui sommes nous ?" />
      <div className="mx-auto max-w-screen-xl">
        <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
          <h1>Qui sommes nous ?</h1>
          <div className="mx-auto std">
            <div className="bg-white">
              <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                  <div className="space-y-5 sm:space-y-4">
                    <p className="text-xl leading-7 text-gray-500">
                      Deltavisit c'est deux amis, Rachid et Nicolas, qui ont
                      joint leurs compétences respectives afin de rendre les
                      relations entre les professionnels et leur clientèle plus
                      transparentes, en valorisant leur image et en facilitant
                      leur communication grâce à une technologie innovante, la
                      visite virtuelle 3D.
                    </p>
                  </div>
                  <div className="lg:col-span-2">
                    <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                      <li className="sm:py-8">
                        <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                          <div className="relative pb-2/3 sm:pt-2/3">
                            <img
                              className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg"
                              src={Rachid}
                              alt="Rachid"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <div className="space-y-4">
                              <div className="text-lg leading-6 font-medium space-y-1">
                                <h4>Rachid Kaïd</h4>
                                <p className="text-red-600">
                                  Co-Fondateur
                                </p>
                              </div>
                              <div className="text-lg leading-7">
                                <p className="text-gray-500">
                                  Rachid est un entrepreneur né. Dès la fin de
                                  ses études il a immédiatement ressenti le
                                  besoin d'être indépendant et est allé au bout
                                  de ses convictions en créant avec succès ses
                                  propres établissements. Sa passion pour
                                  l'immobilier et les nouvelles technologies l'a
                                  emmené vers la visite virtuelle 3D après sa
                                  rencontre avec Nicolas.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="sm:py-8">
                        <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                          <div className="relative pb-2/3 sm:pt-2/3">
                            <img
                              className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg"
                              src={Nicolas}
                              alt="Nicolas"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <div className="space-y-4">
                              <div className="text-lg leading-6 font-medium space-y-1">
                                <h4>Nicolas Pluquet</h4>
                                <p className="text-red-600">
                                  Co-Fondateur / Président
                                </p>
                              </div>
                              <div className="text-lg leading-7">
                                <p className="text-gray-500">
                                  Nicolas quant à lui est un passionné de
                                  photographie, qui après plusieurs années
                                  passées fidèlement dans la même entreprise a
                                  décidé de battre de ses propres ailes en
                                  vivant de sa passion. Il est donc devenu
                                  photographe professionnel spécialisé dans la
                                  mise en valeur d'espaces et fait le bonheur
                                  des agences qui font appel à ses services.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="sm:py-8">
                        <div className="space-y-4 sm:space-y-0">
                          <div className="space-y-4">
                              <div className="text-lg leading-7">
                                <p className="text-gray-500">
                                  Delta Visit utilise la technologie Matterport, leader mondial des visites virtuelles, qui permet de modéliser en 3D n’importe quel espace en un temps record. La caméra Matterport Pro2 allie des captations infra-rouges avec des photographies 360° et permet de restituer une visite virtuelle 3D complètement immersive, parfaite pour l’immobilier (ventes/locations), les commerces, les restaurants, les espaces culturels, l’hôtellerie/gîtes, la construction (BIM/suivi de chantier)…
                                </p>
                              </div>
                            </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default VisitePage;
